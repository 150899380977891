//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import TuoShiLOGO from "../../components/TuoShiLOGO.vue";
export default {
  components: { TuoShiLOGO },
  data() {
    return {
      value: true,
      value1: true,
      value2: true,
      value3: true,
      value4: true,
    };
  },
  methods: {
    onConfirm(value) {
      this.value1 = value;
      this.showPicker = false;
    },
  },
};
